body {
  margin: 0;
}

.full_container {
  max-width: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  max-width: 1400px;
  width: 100%;
  margin: auto;
  padding: 20px;
}

.white {
  color: #fff !important;
}

.site_content {
  min-height: calc(100vh - 70px);
}

.submit_button {
  background: #061848;
  border: 1px solid #061848;
  color: #fff;
}

.submit_button:hover {
  background: #fff;
  border: 1px solid #061848;
  color: #061848;
}

.root,
.App,
.ant-layout {
  min-height: 100vh;
}

.App.admin {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.ql-container {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}

.welcome_letter .ql-container {
  height: 100% !important;
  max-height: 350px;
  min-height: 350px;
}

.ql-editor {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}

.welcome_letter .ql-editor {
  height: 100% !important;
  max-height: 350px;
  min-height: 350px;
}

.title_react_quill .ql-editor {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}

.title_react_quill .ql-container {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}

.hidden {
  display: none !important;
}

.ant-layout-content {
  padding: 40px;
}

.ant-layout-content.signin {
  padding: 0 !important;
  background-color: #f8f8f9;
}

.actions-buttons-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ant-input-number {
  width: 100%;
}

.preview_file {
  display: none;
}

.preview_file.hasPreview {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}

.preview_file.sponsor.hasPreview {
  background-size: contain;
}

.preview_file img {
  width: auto;
  height: 90%;
}

.dragger.hasPreview .ant-upload .ant-upload-btn .ant-upload-drag-container p {
  opacity: 0;
}

.admin_layout_content {
  background-color: #f8f8f9;
}

.dashboard_page {
  min-height: 100%;
  background: #fff;
  border-radius: 10px;
}

.dashboard_title_row {
  padding: 20px 20px 0 20px !important;
}

.dashboard_content_row {
  padding: 20px !important;
  overflow: auto;
  min-height: calc(100vh - 290px);
}

.ant-avatar {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-empty {
  color: rgba(0, 0, 0, 0.25);
}

.empty_data svg {
  font-size: 50px;
  color: rgba(0, 0, 0, 0.25);
}

.admin_layout_content::-webkit-scrollbar {
  width: 1em;
}

.admin_layout_content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.admin_layout_content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  outline: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.table_item {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 21, 41, 0.05);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 21, 41, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 21, 41, 0.05);
  border: 2px solid rgba(0, 21, 41, 0.05);
}

.ant-menu .ant-menu-item svg {
  opacity: 0.5;
}

.ant-menu .ant-menu-item-selected svg {
  opacity: 1 !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* TEXT */
/* ------------------------------------- */
/* ------------------------------------- */

h1 {
  font-size: 40px !important;
  color: #202020 !important;
  font-weight: bold !important;
}

h2 {
  font-size: 30px !important;
  color: #202020 !important;
  font-weight: bold !important;
}

h3 {
  font-size: 24px;
  color: #202020 !important;
  font-weight: bold !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* ANTD */
/* ------------------------------------- */
/* ------------------------------------- */

.ant-layout {
  background: #fff;
}

.ant-layout-header {
  height: 85px;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* MEDIA QUERIES */
/* ------------------------------------- */
/* ------------------------------------- */
.content_header .menu_right_side .mobile {
  display: none;
}

.content_header .menu_right_side .desktop {
  display: flex !important;
}

@media only screen and (max-width: 1200px) {
  .content_header .menu_right_side .mobile {
    display: flex !important;
  }

  .content_header .menu_right_side .desktop {
    display: none !important;
  }
}
