
.ant-btn svg {
    margin: 0 !important;
}

.delete-action {
    margin-left: 5px;
}

.welcome-letter-card {
    padding: 30px;
}

.welcome-letter-card .ql-editor {
    height: 300px !important;
}